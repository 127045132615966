import React from 'react';
import { Box } from 'CHAMELEON_REACT_HUB';

const Content = ({ children }) => (
  <Box position="relative" width="full" height="full">
    {children}
  </Box>
);

export default Content;
