import React from 'react';
import Helmet from 'react-helmet';
import { useComponents } from '../../utils/multiBrandedTheme';
import fontData from '../../config/fonts';
import brands from '../../config/brands.json';

const FontPreLoad = () => {
  const RenderBrand = ({ TextComponent, font, ...rest }) => {
    const fontUrl = `${font.baseUrl}/${font.activeVersion}/fonts.css?17032022`;
    return (
      <div {...rest} aria-hidden="true">
        <Helmet>
          <link rel="preload" href={fontUrl} as="style" />
          <link rel="stylesheet" href={fontUrl} />
        </Helmet>
        <TextComponent
          fontFamily="primary"
          fontWeight="regular"
        ></TextComponent>
        <TextComponent fontFamily="primary" fontWeight="medium"></TextComponent>
        <TextComponent fontFamily="primary" fontWeight="bold"></TextComponent>
        <TextComponent
          fontFamily="secondary"
          fontWeight="regular"
        ></TextComponent>
        <TextComponent
          fontFamily="secondary"
          fontWeight="medium"
        ></TextComponent>
        <TextComponent fontFamily="secondary" fontWeight="bold"></TextComponent>
      </div>
    );
  };

  return (
    <>
      {brands
        .filter(brand => brand.active)
        .map(brand => {
          const { Text } = useComponents(brand.abbreviation);

          return (
            <RenderBrand
              key={brand.abbreviation}
              TextComponent={Text}
              font={fontData[brand.abbreviation]}
            />
          );
        })}
    </>
  );
};

export default FontPreLoad;
