import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { colorGreyDark } from '@mediahuis/chameleon-theme-hub';
import { Box, Flex } from 'CHAMELEON_REACT_HUB';
import groupBy from '../../utils/groupBy'; // eslint-disable-line
import sortByOrder from '../../utils/sortByOrder'; // eslint-disable-line
import { useComponents, useIcons } from '../../utils/multiBrandedTheme';
import useThemeContext from '../ThemeContext/useThemeContext';
import NavItem from './NavItem';
import { mapItems, mapCategory } from './utils';

const StyledBox = styled(({ isActive, ...rest }) => <Box {...rest} />)`
  background: ${props =>
    props.isActive ? props.theme.colorPrimary10 : 'none'};
  color: ${props =>
    props.isActive ? props.theme.colorPrimary90 : colorGreyDark};

  &::before {
    content: '';
    display: block;
    height: 4px;
    width: 4px;
    background: ${props =>
      props.isActive ? props.theme.colorPrimary70 : 'none'};
    position: absolute;
    top: 50%;
    left: 12px;
    margin-top: -2px;
    border-radius: 100%;
  }

  &:hover,
  &:focus {
    background: ${({ theme: { colorPrimary10 } }) => colorPrimary10};
    color: ${({ theme: { colorPrimary90 } }) => colorPrimary90};
  }
`;

const StyledLink = styled(Link)`
  flex: 1;
`;

const sortItems = items => {
  const orderedItems = Object.entries(items)
    .map(item => item[1])
    .map(item => {
      return item.filter(item => item.order).sort(sortByOrder);
    })
    .filter(item => item.length);

  const unorderedItems = Object.entries(items)
    .map(item => item[1])
    .map(item => {
      return item.filter(item => !item.order);
    })
    .filter(item => item.length);

  return [...orderedItems, ...unorderedItems];
};

const NavSection = ({ title, mainPath, items, location, external }) => {
  const defaultOpen = location && location.pathname.includes(mainPath);
  const [theme] = useThemeContext();
  const [open, setOpen] = useState(defaultOpen);
  const activeParent =
    location &&
    (mainPath === location.pathname ||
      mainPath === location.pathname.slice(0, -1));
  const grouped = groupBy(items, item => item.node.parent.relativeDirectory);
  const mapped = mapItems(grouped);
  const mappedCategory = mapCategory(mapped);
  const sortedItems = sortItems(mappedCategory);

  const { Text, IconButton } = useComponents();
  const { ChevronDown, ChevronForward } = useIcons();

  /* eslint-disable react/prop-types */
  return (
    <Box width="full">
      {title && (
        <StyledLink to={`${mainPath}${external ? '' : `?brand=${theme}`}`}>
          <StyledBox pl={6} position="relative" isActive={activeParent}>
            <Flex
              alignItems="center"
              justifyContent="space-between"
              position="relative"
              py={2}
              pr={2}
              borderBottomWidth={1}
              borderBottomStyle="solid"
              borderBottomColor="colorGrey20"
            >
              <Text fontFamily="system" size="Caption1" fontWeight="medium">
                {title}
              </Text>

              {external ? (
                <Box height={7} />
              ) : (
                <IconButton
                  onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    setOpen(!open);
                  }}
                  icon={open ? ChevronDown : ChevronForward}
                  size="small"
                  color="colorGreyBase"
                />
              )}
            </Flex>
          </StyledBox>
        </StyledLink>
      )}

      {open &&
        sortedItems.map(items => {
          const nav = [];
          const category = items[0].category ? (
            <Box pl={8} key={items[0].category}>
              <Box
                py={4}
                pr={4}
                borderBottomWidth={1}
                borderBottomStyle="solid"
                borderBottomColor="colorGrey20"
              >
                <Text
                  fontFamily="system"
                  fontWeight="medium"
                  textTransform="uppercase"
                  color="colorGrey80"
                  size="Caption2"
                >
                  {items[0].category}
                </Text>
              </Box>
            </Box>
          ) : null;
          nav.push(category);
          const navItems = items.map(page => {
            const active =
              location &&
              location.pathname.includes(`${page.relativeDirectory}/`);

            return (
              <NavItem
                key={page.path}
                to={page.path}
                text={page.title}
                active={active}
              />
            );
          });
          nav.push(navItems);
          return nav;
        })}
    </Box>
  );
  /* eslint-enable react/prop-types */
};

NavSection.propTypes = {
  items: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.arrayOf(
      PropTypes.shape({
        edges: PropTypes.arrayOf(
          PropTypes.shape({
            node: PropTypes.shape({
              frontmatter: PropTypes.shape({
                path: PropTypes.string,
                name: PropTypes.string,
              }),
            }),
          })
        ),
      })
    ),
  ]),
  location: PropTypes.shape({
    hash: PropTypes.string,
  }),
  title: PropTypes.string,
};

export default NavSection;
