const neededTokens = [
  'fontFamilyBrandPrimary',
  'colorBlackBase',
  'colorPrimaryBase',
  'colorPrimaryDark',
  'colorPrimary10',
  'colorPrimary20',
  'colorPrimary30',
  'colorPrimary40',
  'colorPrimary50',
  'colorPrimary70',
  'colorPrimary80',
  'colorPrimary90',
  'colorPrimary100',
  'colorPrimaryLight',
  'colorSecondaryBase',
  'colorSecondaryDark',
  'colorTextSecondaryBase',
  'colorSecondary40',
  'colorSecondary50',
  'colorSecondary60',
  'colorWhiteBase',
  'colorGrey10',
  'colorGrey60',
  'colorGrey80',
  'motionDurationFast',
  'motionDurationMedium',
  'space0',
  'space2',
  'space5',
  'space6',
  // 'space8',
  'space11',
  'motionDurationSlow',
  'gridBreakpointLarge',
  'gridBreakpointMedium',
  'gridBreakpointSmall',
  'aliasBackdropColorBase',
  'aliasBackdropOpacityBase',
  'shadow1',
  'shadow2',
];

export default neededTokens;