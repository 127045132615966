import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import styled from 'styled-components';
import {
  space6,
  colorGrey60,
  borderRadiusFill,
} from '@mediahuis/chameleon-theme-hub';
import { Caption, Box } from 'CHAMELEON_REACT_HUB';
import { useThemeContext } from '../ThemeContext';

const ItemBox = styled(({active, ...rest}) => <Box {...rest} />)`
  color: ${props => (props.active ? props.theme.colorPrimary90 : colorGrey60)};
  background-color: ${props =>
    props.active ? props.theme.colorPrimary10 : 'none'};

  &::before {
    content: '';
    display: block;
    height: 4px;
    width: 4px;
    background: ${props =>
      props.active ? props.theme.colorPrimary70 : 'none'};
    position: absolute;
    top: 50%;
    left: ${space6};
    margin-top: -2px;
    border-radius: ${borderRadiusFill};
  }

  &:hover {
    background: ${({ theme: { colorPrimary10 } }) => colorPrimary10};
    color: ${({ theme: { colorPrimary90 } }) => colorPrimary90};
  }
`;

const NavItem = ({ to, text, active }) => {
  const [theme] = useThemeContext();
  return (
    <Link to={`${to}?brand=${theme}`}>
      <ItemBox px={8} py={4} active={active} position="relative">
        <Caption fontWeight={active ? 'medium' : 'regular'}>
          {text}
        </Caption>
      </ItemBox>
    </Link>
  );
};

NavItem.propTypes = {
  active: PropTypes.bool,
  depth: PropTypes.number,
  text: PropTypes.string,
  to: PropTypes.string,
};

export default NavItem;
