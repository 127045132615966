/**
 *
 * NOTE:
 *
 * THIS LOOKS TO WORK PRETTY WELL.
 * But if we want to optimize the search queries, we ned to do the following:
 *
 * https://docsearch.algolia.com/docs/required-configuration/
 *
 */
import React, { useEffect } from 'react';
import mousetrap from 'mousetrap';
import { createGlobalStyle } from 'styled-components';
import {
  colorGrey20,
  colorGrey60,
  fontFamilySystem,
  shadow2,
} from '@mediahuis/chameleon-theme-hub';
import { useComponents, useIcons } from '../../utils/multiBrandedTheme';
import { useThemeContext } from '../ThemeContext';
// import { TextField } from 'CHAMELEON_REACT_HUB';

export const DocSearchTheme = createGlobalStyle`
    /* Main dropdown wrapper */
  .algolia-autocomplete .ds-dropdown-menu {
    font-family: ${fontFamilySystem} !important;

    @media only screen and (max-width: ${({ theme }) =>
      theme.gridBreakpointMedium}) {
        min-width: 100px !important;
        max-width: 100% !important;
        width: 100% !important;
    }
  }

  .algolia-docsearch-suggestion--category-header {
    padding: 4px 0 12px 0 !important;
    border-bottom-color: ${colorGrey20} !important;
  }

  .algolia-autocomplete .algolia-docsearch-suggestion--content:before,
  .algolia-autocomplete .algolia-docsearch-suggestion--subcategory-column:before {
    background: ${colorGrey20} !important;
  }

  .algolia-docsearch-suggestion--subcategory-column,
  .algolia-docsearch-suggestion--content {
    padding: 12px 16px !important;
  }

  .algolia-autocomplete .algolia-docsearch-suggestion--title {
    color: ${colorGrey60} !important;
  }

  /* Highlighted text */
  .algolia-autocomplete .algolia-docsearch-suggestion--highlight {
    color: ${({ theme: { colorPrimaryBase } }) => colorPrimaryBase} !important;
    padding: 0 !important;
  }

  .algolia-autocomplete .algolia-docsearch-suggestion--wrapper {
    padding-top: 0 !important;
  }

  .algolia-autocomplete .ds-dropdown-menu {
    box-shadow: ${shadow2} !important;
  }

  .algolia-autocomplete .ds-dropdown-menu .ds-suggestion.ds-cursor .algolia-docsearch-suggestion:not(.suggestion-layout-simple) .algolia-docsearch-suggestion--content {
    background-color: ${({ theme: { colorPrimary10 } }) =>
      colorPrimary10} !important;
  }

  .algolia-autocomplete .ds-dropdown-menu:before,
  .algolia-autocomplete .ds-dropdown-menu [class^=ds-dataset-] {
    border-color: ${({ theme: { colorPrimary10 } }) =>
      colorPrimary10} !important;
  }
`;

const DocSearch = () => {
  const INPUT_SELECTOR = 'chameleon-doc-search';
  const [theme] = useThemeContext();

  useEffect(() => {
    // Focus on search when `/` is pressed.
    if (typeof window !== 'undefined') {
      mousetrap.bind(['/'], focusInput, 'keyup');
    }

    // eslint-disable-next-line global-require
    const docsearch = require('docsearch.js');

    // https://github.com/algolia/docsearch/issues/954 seems related to having to use this unfortunate setTimeout.
    setTimeout(() => {
      docsearch({
        start_urls: ['http://chameleon.mediahuis.be'],
        sitemap_urls: ['http://chameleon.mediahuis.be/sitemap.xml'],
        stop_urls: [],
        apiKey: 'e3c7c525a36b22df30bbd3bcbc2a2f4b',
        indexName: 'mediahuis_chameleon',
        inputSelector: `#${INPUT_SELECTOR}`,
        lvl1: '.DocSearch-lvl1',
        lvl2: '.DocSearch-lvl2',
        lvl3: '.DocSearch-lvl3',
      });
    }, 1000);

    return () => {
      if (typeof window !== 'undefined') {
        mousetrap.unbind(['/'], focusInput);
      }
    };
  }, [theme]);

  const focusInput = () => {
    document.getElementById(INPUT_SELECTOR).focus();
  };

  const { TextField } = useComponents();
  const { Search } = useIcons();

  return (
    <TextField
      iconLeft={Search}
      width="full"
      type="text"
      label="Search"
      labelHidden
      placeholder="Search..."
      id={INPUT_SELECTOR}
    />
  );
};

export default DocSearch;
