import React from 'react';
import { useThemeContext } from '../ThemeContext';
import { useComponents } from '../../utils/multiBrandedTheme';
import brands from 'config/brands';

export default function Draft(props) {
  const [theme] = useThemeContext();
  const { Box, Caption } = useComponents();
  const brand = brands.find(b => b.abbreviation === theme);
  
  if (brand.experimental) {
    return (
      <Box display="inline-block" py={2} borderRadius="sides" px={4} bgColor="red10" color="redDark" {...props}>
        <Caption level={2}>Draft</Caption>
      </Box>
    )
  }

  return null;
}