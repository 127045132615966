import React, { useMemo } from 'react';
import { ThemeProvider } from 'styled-components';
import useThemeContext from '../ThemeContext/useThemeContext';
import { useStyleguide } from '../../utils/multiBrandedTheme';
import neededTokens from '../../config/neededTokens';

const BrandThemeProvider = ({ children }) => {
  const [theme] = useThemeContext();
  const tokens = useStyleguide('js');

  const themeValues = useMemo(() => {
    const neededTokensForBrand = tokens.filter(token =>
      neededTokens.includes(token.name)
    );
    const returnValue = {};
    neededTokensForBrand.forEach(
      token => (returnValue[token.name] = token.value)
    );

    if (theme === 'hbvl') {
      returnValue.codePreviewPrimary = returnValue.colorSecondaryBase;
      returnValue.codePreviewSecondary = returnValue.colorPrimaryDark;
      returnValue.brandSwitcherIconColor = returnValue.colorBlackBase;
      returnValue.brandSwitcherActiveIconColor = returnValue.colorBlackBase;
    } else {
      returnValue.codePreviewPrimary = returnValue.colorPrimaryBase;
      returnValue.codePreviewSecondary = returnValue.colorSecondaryBase;
      returnValue.brandSwitcherIconColor = returnValue.colorPrimaryBase;
      returnValue.brandSwitcherActiveIconColor = returnValue.colorWhiteBase;
    }

    return returnValue;
  }, [theme]);

  if (typeof window === 'undefined') {
    // div needed for theme update
    return (
      <div>
        <ThemeProvider theme={themeValues}>{children}</ThemeProvider>
      </div>
    );
  }

  return <ThemeProvider theme={themeValues}>{children}</ThemeProvider>;
};

export default BrandThemeProvider;
