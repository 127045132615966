import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';

const ChameleonLogo = props => {
  const brandTheme = useContext(ThemeContext);

  return (
    <svg
      width="177"
      height="32"
      viewBox="0 0 177 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.8 0.0113976H22.4402V0H6.50207V0.0113976H0V18.948C0 26.7958 5.78509 32 14.4213 32C23.0569 32 28.8 26.7958 28.8 18.948V0.0113976Z"
        fill={brandTheme.colorPrimaryBase}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.8033 15.5249L20.4907 11.3966L16.3034 12.874L16.4043 8.38916H12.0664L12.2169 12.9256L8.03013 11.3966L6.71814 15.5249L10.9556 16.7984L8.23189 20.3663L11.7125 22.9156L14.2351 19.195L16.7582 22.9156L20.2388 20.3663L17.515 16.7984L21.8033 15.5249Z"
        fill={brandTheme.brandSwitcherActiveIconColor}
      />
      <path
        d="M48.0127 24C49.9873 24 51.7595 23.3924 53.0253 22.1772L51.5316 20.4304C50.6709 21.3418 49.481 21.7722 48.1392 21.7722C45.4557 21.7722 43.6835 19.8481 43.6835 16.9873C43.6835 14.1266 45.4557 12.2025 48.1139 12.2025C49.3291 12.2025 50.2658 12.557 51.1266 13.2658L52.5949 11.4937C51.3544 10.5316 49.8608 9.97468 48.0886 9.97468C43.8354 9.97468 41 12.7848 41 16.9873C41 21.1899 43.8101 24 48.0127 24Z"
        fill="#474747"
      />
      <path
        d="M61.4561 9.97468C59.4055 9.97468 57.8105 10.7595 56.8485 12.1519V4H54.2662V23.7468H56.8485V15.6962C56.8485 13.5949 58.3422 12.2025 60.5953 12.2025C62.8485 12.2025 64.3422 13.5949 64.3422 15.6962V23.7468H66.9244V15.2405C66.9244 12.0759 64.7219 9.97468 61.4561 9.97468Z"
        fill="#474747"
      />
      <path
        d="M74.3303 9.97468C72.0771 9.97468 70.4316 10.6076 69.0898 11.7975L70.229 13.3418C71.1404 12.481 72.229 12.0253 73.6974 12.0253C75.9759 12.0253 77.3683 13.3671 77.3683 15.519V16.1266C76.2037 15.8734 74.9632 15.7468 73.8746 15.7468C70.4822 15.7468 68.229 17.4177 68.229 19.9494C68.229 22.3797 70.0771 24 72.786 24C74.6847 24 76.4569 23.038 77.3936 21.3671L77.4442 23.7468H79.9506V15.2405C79.9506 12.0759 77.6974 9.97468 74.3303 9.97468ZM73.5202 22.1266C71.8746 22.1266 70.9379 21.2405 70.9379 19.8734C70.9379 18.481 72.229 17.5443 74.2037 17.5443C75.1911 17.5443 76.305 17.6709 77.3683 17.8987C77.343 20.5823 75.3936 22.1266 73.5202 22.1266Z"
        fill="#474747"
      />
      <path
        d="M98.0121 9.97468C95.9867 9.97468 94.3665 10.8608 93.4804 12.3797C92.645 10.8861 91.1007 9.97468 89.126 9.97468C87.2779 9.97468 85.7842 10.7342 84.8981 12.0759L84.8475 10.2278H82.3665V23.7468H84.9488V15.6962C84.9488 13.5949 86.2652 12.2025 88.2652 12.2025C90.2652 12.2025 91.5817 13.5949 91.5817 15.6962V23.7468H94.164V15.6962C94.164 13.5949 95.5057 12.2025 97.5057 12.2025C99.5057 12.2025 100.822 13.5949 100.822 15.6962V23.7468H103.404V15.2405C103.404 12.0759 101.253 9.97468 98.0121 9.97468Z"
        fill="#474747"
      />
      <path
        d="M118.102 16.8101C118.102 12.7089 115.495 9.97468 111.571 9.97468C107.621 9.97468 105.014 12.8101 105.014 17.038C105.014 21.2152 107.697 24 111.672 24C114.153 24 116.229 22.9114 117.495 21.038L115.52 19.9747C114.583 21.1899 113.267 21.9494 111.647 21.9494C109.292 21.9494 108.001 20.3797 107.748 17.924H118.052C118.102 17.6203 118.102 17.1392 118.102 16.8101ZM111.571 12.0253C113.798 12.0253 115.216 13.5696 115.444 16.1519H107.748C107.976 13.5949 109.343 12.0253 111.571 12.0253Z"
        fill="#474747"
      />
      <path d="M119.813 23.7468H122.396V4H119.813V23.7468Z" fill="#474747" />
      <path
        d="M137.302 16.8101C137.302 12.7089 134.695 9.97468 130.771 9.97468C126.821 9.97468 124.214 12.8101 124.214 17.038C124.214 21.2152 126.897 24 130.872 24C133.353 24 135.429 22.9114 136.695 21.038L134.72 19.9747C133.783 21.1899 132.467 21.9494 130.847 21.9494C128.492 21.9494 127.201 20.3797 126.948 17.924H137.252C137.302 17.6203 137.302 17.1392 137.302 16.8101ZM130.771 12.0253C132.998 12.0253 134.416 13.5696 134.644 16.1519H126.948C127.176 13.5949 128.543 12.0253 130.771 12.0253Z"
        fill="#474747"
      />
      <path
        d="M145.19 24C149.393 24 152.228 21.1899 152.228 16.9873C152.228 12.7848 149.393 9.97468 145.19 9.97468C140.963 9.97468 138.127 12.7848 138.127 16.9873C138.127 21.1899 140.963 24 145.19 24ZM145.19 21.7722C142.558 21.7722 140.811 19.8481 140.811 16.9873C140.811 14.1266 142.558 12.2025 145.19 12.2025C147.798 12.2025 149.545 14.1266 149.545 16.9873C149.545 19.8481 147.798 21.7722 145.19 21.7722Z"
        fill="#474747"
      />
      <path
        d="M161.154 9.97468C159.079 9.97468 157.458 10.7848 156.496 12.2278L156.446 10.2278H153.965V23.7468H156.547V15.6962C156.547 13.5949 158.041 12.2025 160.294 12.2025C162.547 12.2025 164.041 13.5949 164.041 15.6962V23.7468H166.623V15.2405C166.623 12.0759 164.42 9.97468 161.154 9.97468Z"
        fill="#474747"
      />
    </svg>
  );
};

export default ChameleonLogo;
