import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import ThemeSwitch from 'components/ThemeSwitch';
import Draft from 'components/Draft';
import { Caption, Box, Hidden, useMediaQuery } from 'CHAMELEON_REACT_HUB';
import ChameleonLogo from 'images/ChameleonLogo';
import {
  usePackageJson,
  useComponents,
  useIcons,
} from '../../utils/multiBrandedTheme';
import { useThemeContext } from '../../components/ThemeContext';
import { useNavContext } from '../NavContext';

const Logo = styled(ChameleonLogo)`
  height: 30px;
  transition: all ${({ theme }) => theme.motionDurationFast};
  display: block;

  &:hover {
    opacity: 0.9;
  }
`;

const LogoLink = styled(Link)`
  @media only screen and (min-width: ${({ theme }) =>
      theme.gridBreakpointMedium}) {
    width: 264px; /* sidenav:288px - space:24px = 264px */
  }
`;

const UnderlinedLink = styled(Link)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &,
  &:hover,
  &:focus {
    text-decoration: underline;
  }

  &:hover {
    opacity: 0.8;
  }

  @media only screen and (max-width: ${({ theme }) =>
      theme.gridBreakpointLarge}) {
    display: block;
  }
`;

const generatePackageLink = (theme, version) => {
  return `https://mediahuis.visualstudio.com/DNM/_packaging?_a=package&feed=pulse&package=%40mediahuis%2Fchameleon-theme-${theme}&protocolType=Npm&version=${version}`;
};

const TopNav = () => {
  const [theme] = useThemeContext();
  const packageJson = usePackageJson();
  const [show, setShow] = useNavContext();
  const { Paper, IconButton, Flex, Icon } = useComponents();
  const { Menu, WarningFill } = useIcons();

  const captionLevel = useMediaQuery({ xl: 1 }, 2);

  const HeaderTop = styled(Paper)`
    padding-left: ${({ theme }) => theme.space5};
    padding-right: ${({ theme }) => theme.space5};

    @media only screen and (min-width: ${({ theme }) =>
        theme.gridBreakpointMedium}) {
      padding-left: ${({ theme }) => theme.space6};
      padding-right: ${({ theme }) => theme.space6};
    }
  `;

  return (
    <header>
      <Paper
        position="fixed"
        top={0}
        left={0}
        width="full"
        zIndex={100}
        height={10}
      >
        <div
          style={{
            backgroundColor: '#FFF4E6',
            color: '#7B4600',
            padding: '16px',
            fontSize: '16px',
            fontFamily: 'system-ui',
          }}
        >
          <Icon as={WarningFill} mr={3} />
          <span style={{ verticalAlign: 'middle' }}>
            You are currently viewing outdated (&lt;=3.x) Chameleon
            documentation on our old website.{' '}
            <a
              href="https://chameleon.mediahuis.com"
              style={{
                textDecoration: 'underline',
              }}
            >
              For version &gt;=4.x, check out our new website.
            </a>
          </span>
        </div>
        <HeaderTop
          display="flex"
          borderTopStyle="solid"
          borderTopWidth={4}
          borderTopColor="primaryBase"
          bgColor="whiteBase"
          py={5}
          flexDirection="row"
          alignItems="center"
        >
          <Hidden as={Box} mr={2} above="sm">
            <IconButton
              icon={Menu}
              onClick={() => setShow(!show)}
              aria-hidden="true"
              size="small"
            />
          </Hidden>
          <LogoLink to="/">
            <Logo />
          </LogoLink>
          <Flex alignItems="center">
            <Hidden below="md">
              <ThemeSwitch />
            </Hidden>
            <Draft ml={4} />
          </Flex>
          <Box ml="auto" color="colorGreyDark">
            <Caption
              ml={3}
              fontWeight="medium"
              style={{ WebkitFontSmoothing: 'antialiased' }}
              level={captionLevel}
              textAlign="right"
            >
              <Hidden below="lg" as="span">
                Current release:{' '}
              </Hidden>
              <UnderlinedLink to={`/updates/changelog?brand=${theme}`}>
                {packageJson.version}
              </UnderlinedLink>
              <Hidden below="lg" as="span">
                {' '}
                |{' '}
              </Hidden>
              <UnderlinedLink
                as="a"
                target="_blank"
                href={generatePackageLink(theme, packageJson.version)}
              >
                {theme}
              </UnderlinedLink>
            </Caption>
          </Box>
        </HeaderTop>
        <Hidden above="sm">
          <Box
            width="full"
            px={5}
            bgColor="whiteBase"
            py={3}
            borderBottomWidth={1}
            borderBottomColor="grey20"
            borderBottomStyle="solid"
          >
            <ThemeSwitch width="full" />
          </Box>
        </Hidden>
      </Paper>
    </header>
  );
};

export default TopNav;
