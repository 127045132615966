import React from 'react';
import styled from 'styled-components';
import { useComponents, useIcons } from '../../utils/multiBrandedTheme';

const Fab = () => {
  const { Icon, Flex, Text } = useComponents();
  const { CustomerSupport } = useIcons();

  const TextWrap = styled.span`
    opacity: 0;
    transition: max-width 0.35s ease-out, opacity 0.35s;
    text-align: left;
    max-width: 0;
  `;

  const Wrapper = styled(props => (
    <Flex
      borderRadius="sides"
      alignItems="center"
      p={5}
      as="a"
      href="https://jira.mediahuis.nl/servicedesk/customer/portal/39/create/254"
      target="_blank"
      {...props}
    />
  ))`
    overflow: hidden;
    cursor: pointer;
    border: none;
    background: ${({ theme }) => theme.colorSecondaryBase};
    color: ${({ theme }) => theme.colorTextSecondaryBase};
    box-shadow: ${({ theme }) => theme.shadow2};

    &:hover,
    &:focus,
    &:active {
      color: ${({ theme }) => theme.colorTextSecondaryBase};
    }

    .icon {
      transition: 0.35s;
    }

    &:focus,
    &:hover {
      ${TextWrap} {
        max-width: 150px;
        opacity: 1;
        transition: max-width 0.35s ease-out, opacity 0.2s;
      }

      .icon {
        transform: scale(1.2);
      }
    }

    &:focus {
      outline: none;
    }

    &:active {
      box-shadow: ${({ theme }) => theme.shadow1};
      background: ${({ theme }) => theme.colorSecondaryDark};
    }
  `;

  const T = styled(Text)`
    white-space: nowrap;
    overflow: hidden;
    padding-left: 8px;
  `;

  return (
    <Wrapper>
      <Icon className="icon" as={CustomerSupport} flexShrink={0} />
      <TextWrap>
        <T fontFamily="system" size="Heading6">
          Report a bug
        </T>
      </TextWrap>
    </Wrapper>
  );
};

export default Fab;
