import React from 'react';
import canUseDom from '../../utils/canUseDom';
import NavContext from './NavContext';

const noop = () => console.log('noop');

const useNavContext = () => {
  if (canUseDom()) {
    // eslint-disable-next-line
    return React.useContext(NavContext);
  }

  // Fallback
  return [false, noop];
};

export default useNavContext;
