import React, { lazy, useEffect, useState, useRef } from 'react';
import brands from 'config/brands';
import styled from 'styled-components';
import { useThemeContext } from '../ThemeContext';
import { useComponents, useIcons } from '../../utils/multiBrandedTheme';
import { useFocusTrap } from '../../hooks';

const importMenu = () => lazy(() => import(`./Menu`));

export default function Select({ width = 'auto' }) {
  const rootRef = useRef(null);
  const [menu, setMenu] = useState(null);
  const [open, setOpen] = useState(false);
  const [theme] = useThemeContext();
  const { Flex, Icon, Text } = useComponents();
  const { Brand, ChevronDown } = useIcons();
  const activeThemeObject = brands.find(brand => brand.abbreviation === theme);

  useEffect(() => {
    async function loadMenu() {
      const Menu = await importMenu();
      setMenu(
        <Menu
          width={width !== 'full' ? '400px' : width}
          onClose={() => setOpen(false)}
        />
      );
    }

    loadMenu();
  }, []);

  const MenuTrigger = styled(rest => <Flex as="button" {...rest} />)`
    &:hover,
    &:active,
    &:focus {
      cursor: pointer;
      transition: ${({ theme }) => theme.motionDurationFast};
      background-color: ${({ theme }) => theme.colorPrimary20}!important;
    }
    &:focus {
      outline: none;
      border: 1px solid ${({ theme }) => theme.colorPrimary30}!important;
    }
  `;

  useFocusTrap(rootRef.current, open, {
    clickOutsideDeactivates: true, // we need to be able to click on the backdrop
    escapeDeactivates: true,
    returnFocusOnDeactivate: true,
    onDeactivate: () => setOpen(false), // use the focus trap to close the Dialog
  });

  return (
    <div ref={rootRef}>
      <MenuTrigger
        width={width}
        onClick={() => setOpen(!open)}
        borderRadius="sides"
        bgColor="primary10"
        color="primary70"
        borderColor="primary20"
        borderWidth={1}
        borderStyle="solid"
        px={5}
        py={2}
        alignItems="center"
        justifyContent="center"
      >
        <Flex as="span" alignItems="center">
          <Icon as={Brand} mr={2} />
          <Text size="Heading6" fontFamily="system" fontWeight="regular">
            {activeThemeObject.name}
          </Text>
          <Icon as={ChevronDown} size="small" ml={3} />
        </Flex>
      </MenuTrigger>
      {open && (
        <React.Suspense fallback={<div></div>}>
          <div style={{ position: 'relative' }}>{menu}</div>
        </React.Suspense>
      )}
    </div>
  );
}
