import React, { useLayoutEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import NavSection from './NavSection';
import styled from 'styled-components';
import {
  colorWhiteBase,
  gridBreakpointMedium,
  gridBreakpointLarge,
  colorGrey10,
} from '@mediahuis/chameleon-theme-hub';
import { Box, Hidden } from 'CHAMELEON_REACT_HUB';
import { useBodyScrollLock } from '../../hooks';
import { useNavContext } from '../NavContext';
import DocSearch from '../DocSearch';

const Wrapper = styled(Box)`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 288px;
  height: calc(100vh - 64px - 48px);
  height: calc(${props => props.vh} - 64px - 48px);
  background: ${colorWhiteBase};
  border: 1px solid ${colorGrey10};
  border-bottom: 0;

  @media screen and (max-width: ${gridBreakpointMedium}) {
    ${props => props.show && `transition: transform 0.3s`};
    ${props =>
      props.show
        ? `transform: translateX(0);`
        : `transform: translateX(-100%);`};
  }

  @media screen and (max-width: ${gridBreakpointLarge}) {
    height: calc(100vh - 64px - 76px);
    height: calc(${props => props.vh} - 64px - 76px);
  }
`;

const Backdrop = styled(({ show, ...rest }) => <Box {...rest} />)`
  transition: opacity 0.3s;
  background: ${({ theme }) => theme.aliasBackdropColorBase};
  opacity: ${({ theme, show }) => (show ? theme.aliasBackdropOpacityBase : 0)};
  ${props =>
    props.show ? `transform: translateX(0);` : `transform: translateX(-100%);`};
`;

const ScrollContainer = styled.div`
  padding: 0;
  overflow-y: auto;
  height: calc(100% - 96px - 48px);
`;

let scrollPosition = 0;

const SideNav = ({ location, pageContext, menuItems }) => {
  const [show, setShow] = useNavContext();
  const wrapper = useRef();
  const [vh, setVh] = useState(`100vh`);

  useLayoutEffect(() => {
    setShow(false);
    wrapper.current.scrollTop = scrollPosition;
  }, []);

  useLayoutEffect(() => {
    if (window) {
      setVh(`${window.innerHeight}px`);

      window.addEventListener('resize', function() {
        setVh(`${window.innerHeight}px`);
      });
    }
  }, [show]);

  function scroll(e) {
    scrollPosition = e.target.scrollTop;
  }

  useBodyScrollLock(show);

  return (
    <React.Fragment>
      <Hidden above="sm">
        <Backdrop
          show={show}
          onClick={() => setShow(false)}
          height={vh}
          width="100vw"
          top={0}
          left={0}
          position="fixed"
        />
      </Hidden>

      <Wrapper vh={vh} show={show} as="aside">
        <Box px={6} py={6} bgColor="whiteBase">
          <DocSearch />
        </Box>

        <ScrollContainer onScroll={scroll} ref={wrapper}>
          {menuItems.map(({ title, items, mainPath, external }) => {
            return (
              /* this is the parent LI of the list */
              <NavSection
                title={title}
                location={location}
                pageContext={pageContext}
                items={items}
                mainPath={mainPath}
                external={external}
                key={mainPath}
              />
            );
          })}
        </ScrollContainer>
      </Wrapper>
    </React.Fragment>
  );
};

SideNav.propTypes = {
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      mainPath: PropTypes.string,
      items: PropTypes.array,
    })
  ),
  location: PropTypes.shape({
    hash: PropTypes.string,
    host: PropTypes.string,
    hostname: PropTypes.string,
    href: PropTypes.string,
    origin: PropTypes.string,
    pathname: PropTypes.string,
    port: PropTypes.string,
    protocol: PropTypes.string,
    search: PropTypes.string,
  }),
  pageContext: PropTypes.shape({
    frontmatter: PropTypes.shape({
      name: PropTypes.string,
      menu: PropTypes.number,
    }),
  }),
};

export default SideNav;
