import React from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import Helmet from 'react-helmet';
import { DocSearchTheme } from '../DocSearch';
import Fab from '../Fab';
import { useComponents } from '../../utils/multiBrandedTheme';
import FontPreLoad from '../FontPreLoad';
import { useThemeContext } from '../ThemeContext';

const Wrapper = styled.main`
  margin-left: 0;
  position: relative;
  z-index: 0;
  margin-top: 192px;

  @media screen and (min-width: ${({ theme: { gridBreakpointMedium } }) =>
      gridBreakpointMedium}) {
    margin-top: 64px;
    margin-left: 288px;
  }
`;

const GlobalStyle = createGlobalStyle`
  body {
    background: ${({ theme: { colorWhiteBase } }) => colorWhiteBase};
  }

  blockquote p {
    margin: 0;
  }

  * {
    scroll-margin-top: 5rem;
  }
`;

const Main = ({ children }) => {
  const { Box } = useComponents();
  const [theme] = useThemeContext();

  return (
    <Wrapper>
      <Helmet
        bodyAttributes={{
          class: `chameleon-theme-${theme}`,
        }}
      >
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css2?family=Fira+Code&display=swap"
        />
        <link
          rel="stylesheet"
          href="https://shared.mediahuis.be/reset/v1/index.min.css"
        />
        <link
          rel="stylesheet"
          href="https://cdn.jsdelivr.net/npm/docsearch.js@2/dist/cdn/docsearch.min.css"
        />
      </Helmet>
      <Box position="fixed" right={6} bottom={6} zIndex={900}>
        <Fab />
      </Box>
      <GlobalStyle />
      <FontPreLoad />
      <DocSearchTheme />
      {children}
    </Wrapper>
  );
};

export default Main;
